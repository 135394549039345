@font-face {
  font-family:"Lato";
  src: url("../public/fonts/Lato-Black.woff") format("opentype");
  font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
}

@font-face {
  font-family:"Lato";
  src: url("../public/fonts/Lato-Bold.woff") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
  font-family:"Lato";
  src: url("../public/fonts/Lato-Regular.woff") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
  font-family:"Lato";
  src: url("../public/fonts/Lato-Heavy.woff") format("opentype");
  font-display:auto;font-style:normal;font-weight:800;font-stretch:normal;
}

*, input { font-family: "Lato",sans-serif; }

input:focus { outline: none; }

#root {
  width: 100vw;
  overflow: hidden;
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}